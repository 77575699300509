var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("EditLayout", {
        attrs: {
          "supports-photo": "",
          photo: _vm.photo,
          item: _vm.item,
          "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save")
        },
        on: {
          submit: function($event) {
            _vm.isNew ? _vm.create() : _vm.edit()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }